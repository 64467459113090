import React, { Component } from "react"
import PropTypes from "prop-types"

import Layout from "../../components/Layout/Layout"
import SEO from "../../components/seo"
import NotFound from "../../components/NotFound/NotFound"
import { graphql } from "gatsby"

export const pageQuery = graphql`
  {
    image: file(relativePath: { eq: "Illu-404-FR.png" }) {
      childImageSharp {
        gatsbyImageData(width: 600, layout: CONSTRAINED)
      }
    }
  }
`

export default class NotFoundPage extends Component {
  componentDidMount() {
    this.addGoogleAnalytics()
    this.addGoogleAnalyticsEvent()
  }

  addGoogleAnalytics() {
    const script = document.createElement("script")
    const scriptBody = `var global_clientId_analytics;
    (function (i, s, o, g, r, a, m) {
    i['GoogleAnalyticsObject'] = r; i[r] = i[r] || function () {
    (i[r].q = i[r].q || []).push(arguments)
    }, i[r].l = 1 * new Date(); a = s.createElement(o),
    m = s.getElementsByTagName(o)[0]; a.async = 1; a.src = g; m.parentNode.insertBefore(a, m)
    })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');

    ga('create', 'UA-79552282-1', 'auto', { 'allowLinker': true });
    ga('create', 'UA-79552282-3', { 'name': 'rollupProperty', 'allowLinker': true });

    ga('require', 'linker');
    ga('linker:autoLink', ['app.agicap.fr', 'content.agicap.fr', 'app.agicap.com', 'agicap.com']);
    ga('rollupProperty.require', 'linker');
    ga('rollupProperty.linker:autoLink', ['app.agicap.fr', 'content.agicap.fr', 'app.agicap.com', 'agicap.com']);

    ga('rollupProperty.require', 'GTM-594HVRW');

    ga('send', 'pageview');
    ga('rollupProperty.send', 'pageview');

    ga(function (tracker) {
    global_clientId_analytics = tracker.get('clientId');
    });`
    script.innerHTML = scriptBody
    document.body.appendChild(script)
  }

  addGoogleAnalyticsEvent() {
    const script = document.createElement("script")
    const pathName = this.props.location.pathname
    script.innerHTML =
      "ga('send', { hitType: 'event', eventCategory: 'Website Issue', eventAction: '404', eventLabel: '" +
      pathName +
      "' });"
    document.body.appendChild(script)
  }

  render() {
    const {
      image: { childImageSharp }
    } = this.props.data
    return (
      <Layout>
        <SEO lang="fr" title="404: Not found" />
        <NotFound
          image={childImageSharp}
          title="Oups la page que vous recherchez semble introuvable !"
          message="Elle a probablement été déplacée, mise à jour ou supprimée."
          ctaTitle="Retour à la page d'accueil"
          ctaURL="/fr/"
        />
      </Layout>
    )
  }
}

NotFoundPage.propTypes = {
  location: PropTypes.object,
  data: PropTypes.object
}
