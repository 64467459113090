import React from "react"
import _ from "lodash"
import PropTypes from "prop-types"
import { marked } from "marked"

const MarkdownTitle = props => {
  const { text } = props

  if (_.isEmpty(text)) {
    return null
  }

  const renderer = {
    paragraph(text) {
      return `${text}`
    },
    strong(text) {
      return `<span class="highlight">${text}</span>`
    }
  }
  marked.use({ renderer })
  return <span dangerouslySetInnerHTML={{ __html: marked.parse(text) }} />
}

MarkdownTitle.propTypes = {
  text: PropTypes.string
}

export default MarkdownTitle
